<template>
  <div>
    <JayaTitle class="mb-1" classType="page-title" titleName="Mon potager"/>
    <VGardenList/>
  </div>
</template>

<script>
import VGardenList from '@/components/vgarden/VGardenList.vue'
import JayaTitle from "@/components/global/JayaTitle";

export default {
  name: "VGardenList.vue",
  components: {
    JayaTitle,
    VGardenList,
  }
}
</script>