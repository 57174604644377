<template>
  <div class="vgarden-list max-w-md">
    <b-card v-if="vgardens.length === 0" class="text-center">
      <h3>
        Bienvenue dans l'espace potager
      </h3>
      <p>
        Ici, nous proposons de vous accompagner à la conception et à l'entretien de votre potager.
        Votre potager peut être composé de une ou plusieurs planches potagères.
      </p>
      <p>
        Une planche potagère représente une zone particulière du potager, par exemple:
        une allée du potager complet, une jardinière sur une terrasse, ou bien encore un carré potager sur-élevé.
      </p>
      <p>
        Pour commencer, cliquez sur "Nouvelle planche potagère" ci-dessous.
      </p>
    </b-card>
    <span v-for="(vgarden, index) in vgardens" :key="`${index}`">
      <VGardenCard :title="vgarden.name" :vgardenId="vgarden.id" :vgardenLength="vgarden.length" :vgardenNumbSpecies="specyNumber(vgarden.id)" :vgardenWidth="vgarden.width"/>
    </span>
    <div class="d-flex justify-content-center mt-1 mb-5">
      <b-button
          color="primary"
          icon="add"
          type="filled"
          @click="$router.push({name: 'new-vgarden'})">
        Nouvelle planche potagère
      </b-button>
    </div>
  </div>
</template>

<script>
import VGardenCard from '@/components/vgarden/VGardenCard.vue'
import {BButton, BCard} from "bootstrap-vue";

export default {
  name: 'VGarednList',
  components: {
    VGardenCard,
    BCard,
    BButton,
  },
  computed: {
    vgardens() {
      return this.$store.state.garden.vgardens
    },
  },
  methods: {
    specyNumber(vgardenId) {
      return this.$store.getters["garden/getVGardenSpecyNumber"](vgardenId)
    }
  }
}
</script>

<style lang="scss">
.vgarden-list {
  width: 100%;
  overflow-y: auto;
}

.list-enter-active, .list-leave-active {
  transition: all 1s;
}
</style>
