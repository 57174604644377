<template>
  <div class="vgarden-card cursor-pointer">
    <b-card class="mb-50" no-body @click="$router.push('potager/' + vgardenId)">
      <b-row class="vgarden-card-height" no-gutters>
        <b-col class="vgarden-card-image-container" cols="3">
          <b-card-img
              alt="Icon transplantoire"
              class="vgarden-card-image"
              left
              src="@/assets/images/icons/tools/garden_trowel_dark.svg"/>
        </b-col>
        <b-col class="d-flex align-items-center pl-50 pt-25 pb-25 pr-1 vgarden-card-height" cols="9">
          <b-card-body class="p-0 text-truncate">
            <b-card-text>
              <h4>{{ title }}</h4>
              <div class="text-truncate pr-25">
                {{ vgardenNumbSpecies }} espèces dans {{ vgardenLength }}m x {{ vgardenWidth }}m
              </div>
              <div class="d-flex justify-content-end flex-wrap align-items-center">
                <div v-for="(tasks, index) in taskInfos.filter(tasks => tasks.quantity > 0)" :key="index" class="icon">
                  <img
                      :alt="tasks.alt"
                      :src="tasks.path"
                      class="vgarden-card-icon pl-25">
                  {{ tasks.quantity }}
                </div>
              </div>
            </b-card-text>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script type="text/javascript">
import {BCard, BCardBody, BCardImg, BCardText, BCol, BRow} from "bootstrap-vue";

export default {
  name: "vgarden-card",
  props: {
    title: {
      type: String,
      default: "Default Title"
    },
    subtitle: {
      type: String,
      default: ""
    },
    vgardenId: {
      type: Number,
      default: -1
    },
    vgardenLength: {
      type: Number,
      default: -1
    },
    vgardenWidth: {
      type: Number,
      default: -1
    },
    vgardenNumbSpecies: {
      type: Number,
      default: -1
    },
    gardenIsBalcony: {
      type: Boolean,
      default: true
    },
    thumbnail: {
      type: String,
      default: "vgarden_default"
    }
  },
  components: {
    BCard,
    BCardBody,
    BCardImg,
    BCardText,
    BRow,
    BCol,
  },
  computed: {
    ImageDescription() {
      return "Image de " + this.title
    },
    vGarden() {
      return this.$store.state.garden.vgardens.find(vGarden => this.vgardenId === vGarden.id)
    },
    getPlantTasks() {
      let vGarden = this.$store.state.garden.vgardens.find(vGarden => this.vgardenId === vGarden.id)
      let sowingPlants = []
      let plantingPlants = []
      let trimingPlants = []
      let harvestingPlants = []
      for (const vGargenTask of vGarden.tasks) {
        if (vGargenTask && vGargenTask.object === 'plant') {
          if (vGargenTask.tasks.sow) {
            sowingPlants.push(vGargenTask.plantType)
          }
          if (vGargenTask.tasks.transplant) {
            plantingPlants.push(vGargenTask.plantType)
          }
          if (vGargenTask.tasks.trim) {
            trimingPlants.push(vGargenTask.plantType)
          }
          if (vGargenTask.tasks.harvest) {
            harvestingPlants.push(vGargenTask.plantType)
          }
        }
      }
      return {
        sowing: sowingPlants,
        planting: plantingPlants,
        triming: trimingPlants,
        harvesting: harvestingPlants,
      }
    },
    taskInfos() {
      return [
        {
          alt: "semer",
          path: require("@/assets/images/icons/other/sowing_hand.svg"),
          quantity: this.getPlantTasks.sowing.length
        },
        {
          alt: "transplantoire",
          path: require("@/assets/images/icons/tools/garden_trowel_dark.svg"),
          quantity: this.getPlantTasks.planting.length
        },
        {
          alt: "panier",
          path: require("@/assets/images/icons/tools/basket.svg"),
          quantity: this.getPlantTasks.harvesting.length
        },
        {
          alt: "pelle",
          path: require("@/assets/images/icons/tools/shovel.svg"),
          quantity: this.vGarden.tasks.filter(task => task.object !== 'plant').length
        }]
    },
  }
}
</script>

<style lang="scss">
.vgarden-card {
  position: relative;
  width: 100%;
  margin: auto;
  max-width: 40rem;

  .vgarden-card-height {
    height: 84px;
    @media screen and (min-width: 1200px) {
      height: 100px;
    }
  }

  .vgarden-card-image-container {
    height: 84px;
    width: 100%;
    background: var(--secondary-light);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0 0 0.5rem;
    @media screen and (min-width: 1200px) {
      height: 100px;
    }

    .vgarden-card-image {
      height: 70px;
      @media screen and (min-width: 1200px) {
        height: 85px;
      }
    }
  }

  .vgarden-card-icon {
    height: 20px;
    width: auto;
    margin-right: 2px;
    @media screen and (min-width: 1200px) {
      height: 30px;
    }
  }
}
</style>
